import kendo from '@progress/kendo-ui';
import _ from 'lodash';
import moment from 'moment';

import WindowContainer from './containers/window';
import UserUtils from './user.utils';
import I18n from './i18n/I18nSt';
import colors from '../../../src-react/css/colors';
import Config from './config';
// import { setJwt } from '../../../src-react/actions/proposalBuilder/proposalBuilderActions';

const Utils = {
    /**
     * Returns toast options with no autohide
     * @returns {{autoHideAfter: number, allowHideAfter: number, position: {top: number, right: number}, button: boolean}}
     */
    getPersistentErrorOptions() {
        return {
            autoHideAfter: 0,
            allowHideAfter: 1000,
            position: {
                top: 40,
                right: 40,
            },
            button: true,
        };
    },

    /**
     * Parses url parameters from seach query string
     * @param redirect if true, will be parsing greedily so that 'redirect' parameter could have url-parameterized values like redirect=manage.html?type=package
     * @param queryString
     * @returns {{}}
     */

    parseQueryString(redirect = null, queryString = window.location.search) {
        const splitQueryString = queryString?.substring(queryString.indexOf('?') + 1);
        if (splitQueryString) {
            return splitQueryString
                .split('&')
                .map(part => (redirect ? part.split(/=(.+)/) : part.split('=')))
                .reduce(
                    (params, elem) => ({
                        ...params,
                        [decodeURIComponent(elem[0])]: decodeURIComponent(elem[1]),
                    }),
                    {}
                );
        }
        return {};
    },

    _getToast(opts) {
        let $toast = $('.st-toast');
        const options = opts || {
            autoHideAfter: 2500,
            position: { top: 40, right: 40 },
            button: true,
        };
        if ($toast.length === 0) {
            $toast = $('<div class="st-toast" />');
            $toast.appendTo('body');
        }
        return $toast.kendoNotification(options).data('kendoNotification');
    },

    showErrorToast(msg, options) {
        const toast = this._getToast(options);
        toast.show(msg, 'error');
    },

    showWarningToast(msg, options) {
        const toast = this._getToast(options);
        toast.show(msg, 'warning');
    },

    showInfoToast(msg, options) {
        const toast = this._getToast(options);
        toast.show(msg, 'info');
    },

    redirect(path) {
        if (this.pageInIframe) {
            window.top.location.href = path;
        } else {
            window.location.assign(path);
        }
    },

    pageInIframe() {
        try {
            return window.self !== window.top;
        } catch (e) {
            return true;
        }
    },

    getRedirectUrl(params) {
        return 'redirect' in params && !_.isEmpty(params.redirect.trim())
            ? params.redirect.trim()
            : undefined;
    },

    getPrivileges(STApp) {
        return this._userArrayFieldToMap(STApp, 'privileges');
    },

    getFeatureFlags(STApp) {
        return this._userArrayFieldToMap(STApp, 'feature_flags');
    },

    getCssPath(STApp) {
        return this.getFeatureFlags(STApp).direct_rebranding
            ? 'css/theme/rebrand'
            : 'css/theme/default';
    },

    getCurrentThemeComponents(STApp) {
        return this.getFeatureFlags(STApp).direct_rebranding
            ? this.getRebrandingComponents()
            : this.getDefaultComponents();
    },

    getRebrandingComponents() {
        return {
            manage: { BtnNewProposal: 'btn-white' },
            proposalBuilder: { inputBottomBorderOnFocus: colors.fillRateBarColor },
            proposalSummaryIcons: {
                screen: 'screen_rebrand',
                audience: 'audience_rebrand',
                price: 'price_rebrand',
                cpm: 'cpm_rebrand',
            },
            chart: {
                titleFontWeight: 'bold',
                fontColor: '#2C2C2C',
                bookedColor: '#001464',
                heldColor: '#ACABB7',
                visual: e => {
                    const color = e.options.markers.background;
                    const labelColor = e.options.labels.color;
                    const rect = new kendo.geometry.Rect([0, 0], [100, 50]);
                    const layout = new kendo.drawing.Layout(rect, {
                        spacing: 5,
                        alignItems: 'center',
                    });

                    const borderRect = new kendo.geometry.Rect(
                        new kendo.geometry.Point(0, 0),
                        new kendo.geometry.Size(20, 20)
                    );

                    // eslint-disable-next-line
                    const marker = new kendo.drawing.Path.fromRect(borderRect, {
                        stroke: {
                            color,
                            width: 2,
                        },
                        fill: {
                            color,
                        },
                    });

                    const label = new kendo.drawing.Text(e.series.name, [0, 0], {
                        fill: {
                            color: labelColor,
                        },
                    });

                    layout.append(marker, label);
                    layout.reflow();

                    return layout;
                },
            },
        };
    },

    getDefaultComponents() {
        return {
            manage: { BtnNewProposal: 'btn-primary-inverse' },
            proposalBuilder: { inputBottomBorderOnFocus: colors.primary },
            proposalSummaryIcons: {
                screen: 'screen',
                audience: 'audience',
                price: 'price',
                cpm: 'cpm',
            },
            chart: {
                titleFontWeight: 'normal',
                fontColor: '#838ba8',
                bookedColor: Config.defaults.digital_theme.bookedColor,
                heldColor: Config.defaults.digital_theme.heldColor,
            },
        };
    },

    getDomain(STApp) {
        if (STApp !== undefined && STApp !== null) {
            return STApp._userModel.get('domain');
        }
        return null;
    },

    getRoleId(stApp) {
        if (stApp !== undefined && stApp != null) {
            return stApp._userModel.get('role_id');
        }
        return null;
    },

    _userArrayFieldToMap(STApp, fieldName) {
        if (STApp !== undefined && STApp !== null) {
            const privileges = STApp._userModel.get(fieldName);
            if (privileges) {
                return privileges.reduce((obj, elem) => ({ ...obj, [elem]: true }), {});
            }
        }
        return {};
    },

    getUserCart(STApp) {
        if (STApp !== undefined && STApp !== null) {
            return STApp._userModel.get('cart');
        }
        return null;
    },

    getCurrentUserId(STApp) {
        return !_.isNil(STApp) && STApp._userModel.get('id') ? STApp._userModel.get('id') : false;
    },

    getCurrentUser(STApp) {
        return !_.isNil(STApp) && STApp._userModel.get('id')
            ? {
                  id: STApp._userModel.get('id'),
                  name: STApp._userModel.get('name'),
              }
            : null;
    },

    getPathName() {
        return window.location.pathname;
    },

    extractPathName(event) {
        return event.target.location.pathname;
    },

    _formatNumberBase(callback) {
        return number => {
            let formattedNumber = '0';
            if (!_.isFinite(number)) {
                return formattedNumber;
            }
            if (number >= 1000000000) {
                return `${(number.toFixed(0) / 1000000000)
                    .toFixed(1)
                    .toString()
                    .replace('.0', '')}B`;
            }
            if (number >= 1000000) {
                formattedNumber = `${(number.toFixed(0) / 1000000)
                    .toFixed(1)
                    .toString()
                    .replace('.0', '')}M`;
            } else {
                formattedNumber = callback(number);
            }
            return formattedNumber;
        };
    },

    /**
     * Uses exponential notation to avoid float number rounding issues and JS quirks
     * Round decimals on a number, if number comes with less decimals will return as is
     * @param value
     * @param decimals
     * @return {number}
     */
    roundDecimals(value, decimals) {
        // TODO WEBPACK MIGRATION : make sure this nested template literals work ! Oo
        return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
    },

    formatNumber(number) {
        return this._formatNumberBase(this._getDefaultNumberFormat)(number);
    },

    _getDefaultNumberFormat(number) {
        return number.toFixed(0).toString();
    },

    /**
     *
     * @param {Number} number
     * @param {Object} options
     * @returns {String}
     *      - decimals: number of decimals (thanks captain obvious)
     *      - locale: the country locale you want to format your number for
     *      - style: decimal for plain number formatting, currency for currency formating and percent for percent formatting
     *      - currency: currency code (ex: 'USD') if it's needed (style = currency)
     *      - customCurrency : string to replace standard currency symbol/name (to use with decimal style)
     *      Ex options for currency :
     *       {
     *           decimals: 2,
     *           style: 'currency',
     *           currency: 'USD',
     *      }
     *      Ex options for custom currency :
     *       {
     *           decimals: 2,
     *           style: 'decimal',
     *           customCurrency: UserUtils.get('currency').trim(),
     *      }
     */
    _getLocaleNumberFormat(number, options = { decimals: 0, locale: 'en-US', style: 'decimal' }) {
        const locale = options.locale || 'en-US';
        const decimals = options.decimals || 0;
        const style = options.style || 'decimal';
        const formatterOptions = {
            style,
            minimumFractionDigits: decimals,
            maximumFractionDigits: decimals,
        };
        if (options.currency) {
            formatterOptions.currency = options.currency;
        }
        /* We enforce style as decimal to use a string as custom currency as currency style
            expect a currency value to display it (but we want to replace it with our custom one) */
        if (options.customCurrency) {
            formatterOptions.style = 'decimal';
        }
        const formatter = new Intl.NumberFormat(locale, formatterOptions);

        let formattedNumber = formatter.format(number);
        if (options.customCurrency) {
            formattedNumber = `${options.customCurrency}${formattedNumber}`;
        }
        return formattedNumber;
    },

    /**
     * Wrapper over this._getLocaleNumberFormat - will retrieve custom currency and get additional options on it's own
     * @param number - number to be formatted as custom currency
     * @returns {*|String} - formatted number
     */
    formatCustomCurrency(number) {
        const params = {
            customCurrency: UserUtils.get('currency'),
            decimals: 2,
        };
        return this._getLocaleNumberFormat(number, params);
    },

    formatCPMNumber(number) {
        return this._formatNumberBase(this._getCPMKendoFormatter.bind(this))(number);
    },

    formatRawPrice(number) {
        return this._getCPMKendoFormatter.bind(this)(number);
    },

    formatSOV(sov) {
        return kendo.toString(sov, 'p2');
    },

    _getCPMKendoFormatter(number) {
        return kendo.toString(number, UserUtils._domain.cpm_base_format);
    },

    formatCPMExtended(number) {
        let formattedCPM =
            Math.round(number * 10000) /
            10000; /* to avoid problem with floating numbers convert them to integer based as requirement of max 4 significant digits after decimal point */
        formattedCPM = formattedCPM.toString(); /* strips trailing zeros */

        formattedCPM = kendo.toString(formattedCPM, UserUtils._domain.cpm_extended_format);

        return formattedCPM;
    },

    formatImpressionRaw(number) {
        return this._getDefaultNumberFormat(number);
    },
    /**
     * Returns the number of whole numbers (digits to the left of the decimal).
     * @param number
     * @returns {number}
     * @private
     */
    _getWholeNumberPlacesCount(number) {
        return Math.floor(1 + Math.log(number) / Math.log(10));
    },

    getCookie(cname) {
        const name = `${cname}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i += 1) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    },

    /**
     * Returns formatted string with time difference interval when two dates are compared
     * @param base - Date object used as a base for comparison
     * @param toCompare - Date object to compare with the base date
     * @returns {string} - formatted string representing time difference based on the largest available interval
     */
    getTimeDifference(base, toCompare) {
        // TOREFACTORIMPORT ??? moment
        const diffDuration = moment.duration(base.diff(toCompare));

        const seconds = diffDuration.seconds();
        const minutes = diffDuration.minutes();
        const hours = diffDuration.hours();
        const days = diffDuration.days();
        const months = diffDuration.months();
        const years = diffDuration.years();

        if (years) {
            return years > 1
                ? I18n.getParameterized('LblYearsDiff', { value: years })
                : I18n.getParameterized('LblYearDiff', { value: years });
        }
        if (months) {
            return months > 1
                ? I18n.getParameterized('LblMonthsDiff', { value: months })
                : I18n.getParameterized('LblMonthDiff', { value: months });
        }
        if (days) {
            return days > 1
                ? I18n.getParameterized('LblDaysDiff', { value: days })
                : I18n.getParameterized('LblDayDiff', { value: days });
        }
        if (hours) {
            return hours > 1
                ? I18n.getParameterized('LblHoursDiff', { value: hours })
                : I18n.getParameterized('LblHourDiff', { value: hours });
        }
        if (minutes) {
            return minutes > 1
                ? I18n.getParameterized('LblMinutesDiff', { value: minutes })
                : I18n.getParameterized('LblMinuteDiff', { value: minutes });
        }
        return seconds > 1
            ? I18n.getParameterized('LblSecondsDiff', { value: seconds })
            : I18n.getParameterized('LblSecondDiff', { value: seconds });
    },

    /**
     *
     * @param numOfMonths {Number}
     * @param startDate {Moment}
     */
    monthToDays(numOfMonths, startDate) {
        // TOREFACTORIMPORT ??? moment
        const futureDate = moment(startDate).add(numOfMonths, 'M');
        return futureDate.diff(startDate, 'days');
    },

    numberWithCommas(x) {
        const parts = (x || 0).toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
    },

    /**
     * @param date {string} - UTC DATE will be converted to local date (timezone accounted for)
     * @param showYear
     * @type {string}
     */
    getShortDateFormat(date, showYear) {
        let format;
        if (showYear) {
            format = {
                year: '2-digit',
                month: 'short',
                day: 'numeric',
            };
        } else {
            format = {
                month: 'short',
                day: 'numeric',
            };
        }
        // TOREFACTORIMPORT ??? moment
        return moment(date, 'YYYY-MM-DD')
            .toDate()
            .toLocaleDateString(undefined, format);
    },

    /**
     * Returns UNIX timestamp to be used a url parameter to enforce cache busting for user-uploaded assets
     * @returns {{clear_cache: string}}
     */
    getClearCacheParam() {
        return {
            // TOREFACTORIMPORT ??? moment
            clear_cache: moment().valueOf(),
        };
    },

    /**
     * Stringifies model attributes to back them up
     * (important for complex data attributes like Array or Object as simple clone will create references)
     * @param model
     */
    backUpModel(model) {
        return model.toJSON();
    },

    /**
     * Applies backed attributes to a model and return a model
     * @param modelToRestore - model object to restor
     * @param {} data - backed Attributes
     */
    restoreModel(modelToRestore, data) {
        return modelToRestore.set(JSON.parse(data));
    },

    /**
     *
     * @param price
     * @param audience
     * @return {number}
     */
    getCPMValue(price, audience) {
        if (price && audience) {
            return (price / audience) * 1000;
        }

        return 0;
    },

    setupWindow(options) {
        return new WindowContainer({
            ...{
                modal: true,
                title: false,
                position: {
                    top: '5%',
                    left: '5%',
                },
                width: '90%',
                height: '95%',
                scrollable: true,
            },
            ...options,
        });
    },

    setOverlayMessage(messageContainer, message) {
        $(messageContainer).html(message);
    },

    onHideLoadingDisableMask(loadingScreen, disableOverlay) {
        // disabling loader to allow grid manipulation
        $(loadingScreen).addClass('hidden');
        $(disableOverlay).addClass('hidden');
        $(disableOverlay)
            .parents('body')
            .css('overflow-y', '');
    },

    onShowLoadingDisableMask(loadingScreen, disableOverlay, msg) {
        // enabling loader to prevent grid manipulation
        this.setOverlayMessage(msg || I18n.get('LblLoadingMessage'));
        $(disableOverlay)
            .parents('body')
            .scrollTop(0);
        $(loadingScreen).removeClass('hidden');
        $(disableOverlay).removeClass('hidden');
        $(disableOverlay)
            .parents('body')
            .css('overflow-y', 'hidden');
    },

    /**
     * Returns the expected vs actual error normalized to 1.0
     * @param expected
     * @param actual
     */
    getNormalizedToleranceError(expected, actual) {
        const parsedExpected = parseFloat(expected);
        const parsedActual = parseFloat(actual);
        if (_.isNaN(parsedExpected) || parsedExpected === 0) {
            return null;
        }
        return (parsedActual - parsedExpected) / parsedExpected;
    },

    /**
     * Sanitizes the input string to avoid xss. The poor-man's guard.
     * @param str
     * @returns {string}
     */
    htmlSanitize(str) {
        const temp = document.createElement('div');
        temp.textContent = str;
        return temp.innerHTML;
    },

    isPlatform(urlParams) {
        return 'origin' in urlParams && urlParams.origin.toLowerCase() === 'platform';
    },

    fetchTokenFromParent(setJwtCallback) {
        window.addEventListener('message', ({ data }) => {
            const { type, value = '' } = data;
            if (type === 'token') {
                setJwtCallback({ value });
            }
        });
    },

    fetchTokenFromApi(setJwtFromApiCallback) {
        const REFRESH_TOKEN_INTERVAL = 270000;

        setJwtFromApiCallback();

        setInterval(() => {
            setJwtFromApiCallback();
        }, REFRESH_TOKEN_INTERVAL);
    },
};
export default Utils;
